import { useEffect, useMemo, useState } from 'react';
import { HiExclamation, HiCheckCircle } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CheckboxSelect, ModalBox, Table } from '@pipedataai/storybook';
import { NextArrow } from '@pipedataai/storybook/dist/components/icons/icons';

import { parseError } from 'src/store/services/helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setClearCampaignData } from 'src/store/slices/campaignSlice';
import {
  setClearCampaignsStep,
  setGlobalLoading,
} from 'src/store/slices/storageSlice';
import { useGetCampainDataQuery } from 'src/store/services/create-campaign/endpoints/create-campaign';

import { customToastMessage } from 'src/components/toast';

import CreateCampaignFooter from './blocks/create-campaign-footer';
import useSelectActionsValues, {
  useSelectDistributionValues,
} from './hooks/useSelectActionsValues';
import { TABLE_STEP_FOUR_COLUMNS } from './constants';
import useStepFourDataTransform from './hooks/useStepFourDataTransform';
import useSaveAsDraft from './hooks/useSaveAsDraft';
import useCampaignPublish from './hooks/useCampaignPublish';

const StepFour = () => {
  const { id } = useParams();
  useGetCampainDataQuery(id as string, { skip: !id });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { campaignID } = useAppSelector((state) => state.createCampaignReducer);
  const [campaignPublished, setCampaignPublished] = useState(false);
  const { activeValues, validSelectValue, handleSelectedValue } =
    useSelectActionsValues();
  const { active, allValues, handleSelect } = useSelectDistributionValues();
  const { transformData } = useStepFourDataTransform();
  const { handleSave, saveError } = useSaveAsDraft();
  const [nextLoad, setNextLoad] = useState(false);
  const { result, handlePublish, publishErrorMessage, isPublishLoading } =
    useCampaignPublish();

  const isUpdateCampaign = useMemo(
    () => pathname.includes('update'),
    [pathname]
  );

  const handleNextStep = async () => {
    setNextLoad(true);
    await handlePublish();
    setNextLoad(false);
  };

  const handleGoContinue = () => {
    dispatch(setClearCampaignData());
    dispatch(setClearCampaignsStep());
    navigate('/campaigns');
  };

  const handlePublishClose = () => {
    setCampaignPublished(false);
  };

  const handleSaveAsDraft = async () => {
    dispatch(setGlobalLoading(true));
    const campaign = await handleSave();
    if (campaign && !saveError) {
      dispatch(setGlobalLoading(false));
      customToastMessage(
        `Campaign successfully ${campaignID ? 'updated' : 'created'}.`,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
    }
    if (isUpdateCampaign) {
      dispatch(setGlobalLoading(false));
      navigate('/campaigns');
    }
  };

  useEffect(() => {
    if (publishErrorMessage) {
      const { errorMessage } = parseError(publishErrorMessage);
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
  }, [publishErrorMessage]);

  useEffect(() => {
    dispatch(setGlobalLoading(isPublishLoading));
  }, [dispatch, isPublishLoading]);

  useEffect(() => {
    if (result?.data) {
      setCampaignPublished(true);
    }
  }, [result?.data]);

  return (
    <>
      <div className="mt-20">
        <CheckboxSelect
          title="Select Traffic Distribution"
          type="radio"
          className="max-w-[364px]"
          activeValues={active}
          selectElements={allValues}
          onSelect={handleSelect}
        />
        <CheckboxSelect
          title="Select Conversions Action(s)"
          type="checkbox"
          className="mt-6 mb-8 max-w-[364px]"
          activeValues={activeValues}
          selectElements={validSelectValue}
          onSelect={handleSelectedValue}
          placeholder="Choose Conversion Actions..."
        />
        <Table
          data={transformData}
          columns={TABLE_STEP_FOUR_COLUMNS}
          type="dark"
        />
        <CreateCampaignFooter
          handleNextStep={() => handleNextStep()}
          disabledNextButton={false}
          onDraftSave={handleSaveAsDraft}
          nextButtonLoading={nextLoad}
          saveTitle={isUpdateCampaign ? 'Save and Exit' : undefined}
        />
      </div>
      <ModalBox
        isOpen={campaignPublished}
        onClose={handlePublishClose}
        windowClassName="max-w-lg"
      >
        <ModalBox.Body className="p-8">
          <p className="mb-4 text-2xl font-bold text-gray-900">
            🚀 Campaign successfully launched!
          </p>
          <p className="mb-6 text-base font-normal text-gray-500">
            Congratulations on launching the personalization campaign!
            We&apos;re thrilled to see your hard work paying off. Don&apos;t
            forget to check out the results on the Campaigns page.
          </p>
          <Button
            baseTitle="Continue"
            className="ml-auto"
            Icon={<NextArrow className="ml-2" color="#FFFFFF" />}
            onClick={handleGoContinue}
            reverse
          />
        </ModalBox.Body>
      </ModalBox>
    </>
  );
};

export default StepFour;
